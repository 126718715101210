<template>
  <div class="container-fluid">
    <div class="row">

      <sidebar></sidebar>
      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10 h-100 pb-5">
        <div class="row">
          <div class="col-12 mb-5">
            <app-header></app-header>
            <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
          </div>
        </div>
        <section class="px-0 mx-0 px-xl-5 mx-xl-5 mb-5 pt-5 online-books">
          <CoolLightBox
            :items="items"
            :index="currentPage"
            @close="currentPage = null">
          </CoolLightBox>

          <sk-list v-if="loading || !book" width-class="col-6 col-lg-3" :height="0.4" :items-count="1"></sk-list>
          <div v-else class="row justify-content-start mx-lg-5 px-lg-4">
            <div class="col-sm-3 text-center">
              <!-- First Cover Image -->
              <div class="book__card-img-container bg-white"  @click="index = 0">
                <img :src="book.cover_url + '?token=' + token" class="img-fluid img-cover" >
              </div>
              <div class="d-flex justify-content-center mt-2">
                <div  @click="index = 1">
                  <img :src="pages[0]" class="img-fluid img-prewiev">
                </div>
                <div  @click="index = 2">
                  <img :src="pages[1]" class="img-fluid img-prewiev">
                </div>
              </div>

            </div>
            <div class="col-sm-9 py-3">
              <div class="text-right mb-4 mt-4 mt-sm-0">
                <span class="book-price text-blue f-h-h">&#8382; {{ book.price }}</span>
              </div>
              <!-- Title -->
              <h1 class="display-5">{{ book.name }}</h1>
              <p class="text-muted mt-4 mb-5">
                <strong class="text-blue author">{{ book.authors.length > 1 ? $t('buy-books.authors') : $t('buy-books.author') }}: </strong>
                {{ book.authors.join(', ') }}
              </p>
              <a v-if="book.has_bought" class="btn  btn-primary-red-10 d-block d-sm-inline-block">{{ $t('buy-books.read') }}</a>
              <a v-else href="" class="btn  btn-primary-red-10 d-block d-sm-inline-block">{{ $t('buy-books.buy') }}</a>
            </div>
          </div>
        </section>
        <!-- Book Details -->

      </main>
    </div>
  </div>
</template>
<script>
  import sidebar from '../components/navbar/sidebar';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import CoolLightBox from 'vue-cool-lightbox';
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
  import BookResource from '../api/bookResource';
  import { mapGetters } from 'vuex';
  import SkList from '../components/skeletons/sk-list';

  const bookResource = new BookResource();

  export default {
    name: 'buy-books',
    components: { SkList, sidebar, appHeader, pageHeader , CoolLightBox},
    computed: {
      ...mapGetters({
        token: 'auth/token',
      }),
      pageData() {
        return {
          title: this.$t('buy-books.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('buy-books.title'),
              link: '/buy-books',
              isActive: true,
            }],
        };
      },
    },

    watch: {
      book() {
        this.pages = this.book.pages.map(o => o.url + '?token=' + this.token)
      }
    },

    data () {
      return {
        book: null,
        loading: true,
        currentPage: null,
        pages: []
      }
    },

    created() {
      this.loadBook();
    },

    methods: {
      loadBook() {
        this.loading = true;

        bookResource.book(this.$route.params.bookId)
        .then(response => {
          this.book = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        })
      }
    }
  }
</script>


